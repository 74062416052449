<template>
  <div class="story">
    <div class="top common">
      <img src="../assets/img/品牌故事1.jpg" alt="" />
      <img
        src="../assets/img/arrow.gif"
        class="arrow"
        @click="gotoself('.content')"
        alt=""
      />
      <div class="topleft"><Nav /></div>
    </div>
    <div class="black"></div>
    <div class="common content">
      <img src="../assets/img/logo2.png" alt="" />
      <div class="contain">
        <div class="titlecn">生来故事</div>
        <div class="titleen">The story of ShengLai</div>
        <p>
          中邮普泰通信服务有限公司是由国家经贸委、财政部批准设立的国有大型商贸企业。2020年11月，中邮普泰和雨林古茶坊结合己身优势联合打造“生来”这一全新茶叶品牌。
        </p>
        <p>
          64G,128G的包装规格源自于一日一泡，一周一罐，一月一盒的定量原则，小饼的设计为茶叶的储存提供更方便的收纳形式，铁盒的设计为茶叶的防尘存放提供保障让喝茶更简单，更轻松，更纯粹。
        </p>
        <p>人生忽如寄，莫辜负茶和好天气。</p>
      </div>
    </div>
    <div class="black"></div>
    <Footer />
  </div>
</template>
<script>
// import Title from "../components/Title.vue";
import Footer from "../components/Footer.vue";
import Nav from "@/components/Nav.vue";
export default {
  components: {
    Footer,
    // Title,
    Nav,
  },
  methods: {
    gotoself(key) {
      this.$el.querySelector(key).scrollIntoView({
        behavior: "smooth", // 平滑过渡
        block: "start", // 上边框与视窗顶部平齐
      });
    },
  },
};
</script>
<style lang="less" scoped>
.common {
  margin: 0 auto;
}
.top {
  height: 1080px;
  position: relative;
  // margin-bottom: 38px;
  img {
    width: 100%;
    height: 100%;
  }
  .arrow {
    width: 50px;
    height: 100px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -100%);
  }
  .topleft {
    position: absolute;
    height: 100%;
    width: 415px;
    background: rgba(0, 0, 0, 0.1) no-repeat center;
    left: 0;
    top: 0;
    // background-image: url("https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/logo.png");
    // background-size: 135px 190px;
    z-index: 99;
  }
  .toptitle {
    position: absolute;
    top: 400px;
    right: 0px;
    width: 1505px;
    background: rgba(0, 0, 0, 0.7);
    height: 310px;
    color: #ffffff;
    font-family: "source serif Bold";
    .topcn {
      font-size: 77px;
      // margin-left: 415px;
      margin-top: 34px;
      text-align: center;
      font-weight: 800;
    }
    .topen {
      font-size: 77px;
      // margin-left: 415px;
      text-align: center;
      font-family: "source serif light";
    }
  }
}
.content {
  width: 100%;
  height: 1080px;
  // margin-bottom: 38px;
  position: relative;
  background: url("https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/故事页面图/02-网页品牌故事二级页2.jpg")
    no-repeat;
  background-size: 100% 100%;
  img {
    position: absolute;
    height: 382px;
    width: 273px;
    top: 300px;
    left: 318px;
  }
  .contain {
    padding: 200px 0 0 930px;
    color: #ffffff;
    font-size: 27px;
    font-family: source serif;
    .titlecn {
      font-size: 52px;
      font-family: "source serif ExtraLight";
      font-weight: 600;
    }
    .titleen {
      font-size: 34px;
      margin-bottom: 60px;
      font-weight: 200;
      font-family: "source serif ExtraLight";
    }
    p {
      width: 770px;
      line-height: 60px;
      font-weight: 200;
      // text-indent: 60px;
    }
  }
}
.black {
  height: 80px;
  background: #000;
}
</style>
